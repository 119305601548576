import React, { useState } from 'react';
import './footer.scss';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as registerActions from '../../../actionCreators/auth';
import * as cartActions from '../../../actionCreators/cart';
import * as commonCallsActionCreators from '../../../actionCreators/common';
import localStorageHelper from '../../../utils/localStorageHelper';
import * as commonActions from '../../../actionCreators/common';
import { logoutUser } from '../../../actionCreators/auth';
import { setWindowDtm } from '../../../utils/DTM';
import { setPreviousUrl } from '../../../actionCreators/auth';
import LangPopup from '../../../own-days/CommonComponents/LangPopup/LangPopup';
import SocialMediaLinks from '../../../own-days/SocialMediaLinks/SocialMediaLinks';
import { COUNTRIES_MOBILE } from '../../../CommonComponents/Footer/footer';

const countries = COUNTRIES_MOBILE;

const Footer = props => {
  const {
    dataLocale,
    redisCommonData = {},
    login,
    userInfo,
    history,
    footerNavigationData,
    commonActions,
    logoutUser,
    setPreviousUrl,
  } = props;

  const {
    PRODUCTS,
    FRAMES,
    SUNGLASSES,
    CONTACT_LENS,
    CONTACT_LENSES,
    LENS_GUIDE,
    OWNDAYS_CUSTOMER_GUARANTEES,
    ABOUT,
    SHOPS,
    SERVICES,
    LENS_REPLACEMENT,
    MEMBERSHIP,
    EYE_CAMP,
    SUPPORT,
    CONTACT_US,
    FAQS,
    OPERATING_HOURS,
    COPYRIGHT,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    LOGOUT,
    CONTACT_US_PHONE,
    OPENING_HOURS_TIME,
    NEWS,
    INFORMATION,
    NEW_USERS,
    SIGN_UP_AN_ACCOUNT,
  } = dataLocale;

  const freshBotConfig =
    redisCommonData?.FRESHBOT_CONFIG && JSON.parse(redisCommonData.FRESHBOT_CONFIG);

  const sprinklrBotConfig =
    redisCommonData?.SPRINKLR_BOT_CONFIG && JSON.parse(redisCommonData.SPRINKLR_BOT_CONFIG);

  const sprinklrBotEnabled = sprinklrBotConfig?.msite_enabled === 'ON';

  const showChat = sprinklrBotEnabled ? sprinklrBotConfig?.msite_enabled : freshBotConfig?.enabled;

  const linksBlocks = [
    {
      title: PRODUCTS,
      links: [
        {
          title: FRAMES,
          link: footerNavigationData?.FRAMES,
        },
        {
          title: SUNGLASSES,
          link: footerNavigationData?.SUNGLASSES,
        },
        {
          title: CONTACT_LENSES,
          link: footerNavigationData?.CONTACT_LENS,
        },
        {
          title: LENS_GUIDE,
          link: footerNavigationData?.LENS_GUIDE,
        },
        {
          title: 'Collections',
          link: footerNavigationData?.ALL_COLLECTIONS,
        },
      ],
    },
    {
      title: SERVICES,
      links: [
        {
          title: OWNDAYS_CUSTOMER_GUARANTEES,
          link: footerNavigationData?.CUSTOMER_GUARANTEES,
        },
        {
          title: LENS_REPLACEMENT,
          link: footerNavigationData?.LENS_REPLACEMENT,
        },
        {
          title: 'Care+',
          link: '/sg/en/services/care',
        },
      ],
    },
    {
      title: ABOUT,
      links: [
        {
          title: SHOPS,
          link: footerNavigationData?.SHOPS,
        },
        {
          title: SERVICES,
          link: footerNavigationData?.SERVICES,
        },
        {
          title: MEMBERSHIP,
          link: footerNavigationData?.MEMBERSHIP,
        },
        {
          title: EYE_CAMP,
          link: footerNavigationData?.EYE_CAMP,
        },
        {
          title: 'Latest Campaigns',
          link: footerNavigationData?.NEWS,
        },
        {
          title: 'Career',
          link: 'https://www.odsaiyou.com/sg/',
        },
      ],
    },
    {
      title: SUPPORT,
      links: [
        {
          title: FAQS,
          link: footerNavigationData?.FAQ,
        },
      ],
    },
    {
      title: CONTACT_US,
      link: footerNavigationData?.CONTACT_US,
    },
    {
      title: NEW_USERS,
      link: footerNavigationData?.NEW_USERS,
    },
    {
      title: SIGN_UP_AN_ACCOUNT,
      link: footerNavigationData?.SIGN_UP_AN_ACCOUNT,
    },
  ];

  const [showLangModal, setShowCLangModal] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = index => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const chatHandler = () => {
    const email = userInfo?.email || '';
    const telephone = userInfo?.telephone || '';

    history.push({
      pathname: `/support${
        email || telephone ? `?e=${window.btoa(email)}&m=${window.btoa(telephone.toString())}` : ''
      }`,
      state: { email, telephone },
    });
  };

  const dtmMenu = dtmLabel => {
    const dtm = {
      event: 'cta_click',
      ctaname: `hamburger|${dtmLabel}`,
    };

    setWindowDtm(dtm, true);
  };

  const onlogoutClick = () => {
    localStorageHelper.removeItem('isCartDataOnce');
    logoutUser();
    commonActions.validateSessionV2();
    dtmMenu('Logout');
    history.push('/');
    window?.Freshbots?.resetCustomer(true);
    window?.sprChat?.('updateUserSettings');
  };

  return (
    <footer className="app-footer">
      <section className="app-footer-nav">
        <ul className="app-footer-nav__list">
          {linksBlocks.map(({ title, links, link }, index) => (
            <li key={title} className="app-footer-nav__item">
              {links && (
                <>
                  <h6
                    className={`app-footer-nav__header ${
                      activeAccordion === index ? 'active' : ''
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {title}
                  </h6>
                  <div
                    className={`app-footer-nav__body ${activeAccordion === index ? 'active' : ''}`}
                  >
                    {links.map(({ title, link }) => (
                      <a
                        key={title}
                        className="app-footer-nav__link"
                        href={link}
                        rel={title === 'Career' ? 'noopener noreferrer' : ''}
                        target={title === 'Career' ? '_blank' : '_self'}
                      >
                        {title}
                      </a>
                    ))}
                  </div>
                </>
              )}
              {link && (
                <a className="app-footer-nav__header app-footer-nav__header--link" href={link}>
                  {title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </section>
      <section className="app-footer-contact">
        <h6 className="app-footer-contact__title">CONTACT US</h6>
        <a className="app-footer-contact__phone" href={`tel:${CONTACT_US_PHONE}`}>
          {CONTACT_US_PHONE}
        </a>
        <p className="app-footer-contact__hours">
          {OPERATING_HOURS} {OPENING_HOURS_TIME}
        </p>
      </section>
      {footerNavigationData?.SOCIAL_LINKS && (
        <SocialMediaLinks device="mobile" socialLinksdata={footerNavigationData?.SOCIAL_LINKS} />
      )}
      <section className="app-footer-info">
        <p className="app-footer-info__copyright">{COPYRIGHT}</p>
        <div className="app-footer-info__country" onClick={() => setShowCLangModal(true)}>
          <img alt="country" className="app-footer-info__country-img" src={countries[1].img} />
          <span>Singapore</span>
        </div>
        <nav className="app-footer-info__links">
          <a className="app-footer-info__link" href={footerNavigationData?.PRIVACY_POLICY}>
            {PRIVACY_POLICY}
          </a>
          <a className="app-footer-info__link" href={footerNavigationData?.TERMS_AND_CONDITIONS}>
            {TERMS_AND_CONDITIONS}
          </a>
        </nav>
      </section>
      {showLangModal && (
        <LangPopup
          countries={countries}
          device="mobile"
          setShowModal={setShowCLangModal}
          showModal={showLangModal}
        />
      )}
    </footer>
  );
};

Footer.propTypes = {
  login: PropTypes.bool,
  redisCommonData: PropTypes.any,
  userInfo: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    login: state.common.login,
    dataLocale: state.locale,
    askUserLogin: state.common.askUserLogin,
    redisCommonData: state.common.redisCommonData,
    userInfo: state.common.userInfo,
    footerNavigationData: state.header.mobileNavigationData
      ? state.header.mobileNavigationData.footer
      : null,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => dispatch(logoutUser()),
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    setPreviousUrl: data => dispatch(setPreviousUrl(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
